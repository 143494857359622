.MuiTableCell-root.MuiTableCell-head:first-of-type {
    box-shadow: 0 0 0 0 !important;
    border-radius: 0px !important;
}

.MuiTableCell-root.MuiTableCell-head:last-of-type {
    box-shadow: 0 0 0 0 !important;
    border-radius: 0px !important;
}

#react-select-2-listbox {
    z-index: 11111;
    color: #000000;
}

.css-t3ipsp-control, .css-13cymwt-control {
    background-color: transparent !important;
}

.css-1dimb5e-singleValue {
    color: currentColor !important;
}

.itemSelected {
    background-color: rgba(145, 158, 171, 0.16);
}
.imageContain img{
    object-fit:contain;
}